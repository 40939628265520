import React from 'react'
import { Link } from 'react-router-dom'
import useJsonResource from '../hooks/useJsonResource'
import AuthButton from './AuthButton'
import { Dropdown, Icon, Menu } from 'semantic-ui-react'

const MenuBar = () => {
  const [state, setState] = React.useState({ activeItem: '' })
  const url = '/data.json'
  const data = useJsonResource(url)
  const handleItemClick = (e, { name }) => setState({ activeItem: name })

  return (
    <Menu size='large' fixed='top' inverted>
      <Menu.Item
        as={Link}
        to='/'
        name='home'
        active={state.activeItem === 'home'}
        onClick={handleItemClick}
      >
        <Icon circular inverted color='teal' name='users' />
      </Menu.Item>
      {/* <Menu.Item>
        <img src='/logo.png' />
      </Menu.Item> */}
      <Dropdown item text='Entities'>
        <Dropdown.Menu>
          <Menu.Item
            as={Link}
            to='/councils'
            name='councils'
            color='teal'
            active={state.activeItem === 'councils'}
            onClick={handleItemClick}
          />
          <Menu.Item
            as={Link}
            to='/types'
            name='types'
            color='teal'
            active={state.activeItem === 'types'}
            onClick={handleItemClick}
          />
          <Menu.Item
            as={Link}
            to='/agencies'
            name='agencies'
            color='teal'
            active={state.activeItem === 'agencies'}
            onClick={handleItemClick}
          />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text='Reports'>
        <Dropdown.Menu>
          {data.results.map((i) => {
            return (
              <Menu.Item
                as={Link}
                to={'/reports' + i.url}
                name={i.title}
                key={i.url}
              />
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item
        as={Link}
        to='/map'
        name='map'
        color='teal'
        active={state.activeItem === 'map'}
        onClick={handleItemClick}
      />
      <Menu.Item
        as={Link}
        to='/charts'
        name='charts'
        color='teal'
        active={state.activeItem === 'charts'}
        onClick={handleItemClick}
      />

      <Menu.Menu position='right'>
        <Menu.Item
          as={Link}
          to='/faqs'
          name='faqs'
          color='teal'
          active={state.activeItem === 'faqs'}
          onClick={handleItemClick}
        />
        <Menu.Item
          as={Link}
          to='/blog'
          name='blog'
          color='teal'
          active={state.activeItem === 'blog'}
          onClick={handleItemClick}
        />
        <Menu.Item
          as={Link}
          to='/contact'
          name='contact'
          color='teal'
          active={state.activeItem === 'contact'}
          onClick={handleItemClick}
        />
        <Menu.Item
          as={Link}
          to='/user-profile'
          name='profile'
          color='teal'
          active={state.activeItem === 'profile'}
          onClick={handleItemClick}
        >
          <Icon name='lock' />
          User Profile
        </Menu.Item>
        <Menu.Item>
          <AuthButton />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

export default MenuBar
