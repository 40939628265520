import React from 'react'
import ReactDOM from 'react-dom'

import mapboxgl from 'mapbox-gl'
import MapPopup from '../components/MapPopup'
import MapFilter from '../components/MapFilter'
import { Segment } from 'semantic-ui-react'

mapboxgl.accessToken =
  'pk.eyJ1IjoibWF0dC13ZWJzdGVyIiwiYSI6ImNrZTFveTQ3cjAxZ2gycnBmMmk4c2xlYTYifQ.k_18me3RmV-3rObNseL_5g'
const baseUrl = 'https://dev-api.311-data.org'
const boundaryUrl = baseUrl + '/geojson'
const pinUrl = baseUrl + '/geojson/hotspots?type_id='

const Map = () => {
  const mapContainer = React.useRef()
  const [lng, setLng] = React.useState(-118.2460171)
  const [lat, setLat] = React.useState(34.049403)
  const [zoom, setZoom] = React.useState(9)
  const [selectedTypeId, setSelectedTypeId] = React.useState(2)

  React.useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom,
      minZoom: 9,
      maxZoom: 20,
      transition: {
        duration: 300,
        delay: 0,
      },
    })

    map.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      'bottom-right'
    )

    map.on('load', () => {
      map.addSource('council-boundaries', {
        type: 'geojson',
        data: boundaryUrl,
      })
      map.addLayer({
        id: 'council-boundary-layer',
        source: 'council-boundaries',
        type: 'fill',
        paint: {
          'fill-color': '#6c5396',
          'fill-opacity': 0.5,
          'fill-outline-color': 'hsla(100, 100, 100, 1)',
          // "line-width": 2,
          // "line-dasharray": [2, 1]
        },
      })
      map.addSource('request-pins', {
        type: 'geojson',
        data: pinUrl + selectedTypeId,
        // data: 'https://dev-api.311-data.org/requests/pins/open/geojson'
      })
      map.addLayer({
        id: 'request-pin-layer',
        source: 'request-pins',
        type: 'circle',
        paint: {
          'circle-color': [
            'rgb',
            // red is higher when feature.properties.temperature is higher
            ['interpolate', ['linear'], ['get', 'count'], 12, 0, 200, 255],
            // ["ceil", 255, ["get", "count"]],
            // green is always zero
            0,
            // blue is higher when feature.properties.temperature is lower
            0, // ["-", 250, ["get", "count"]]
          ],
          'circle-radius': 5,
          'circle-opacity': 0.75,
        },
      })
    })

    // render the popup when the point it clicked
    map.on('click', 'request-pin-layer', function (e) {
      const coordinates = e.features[0].geometry.coordinates.slice()
      const popupDiv = document.createElement('div')
      const popup = MapPopup(
        e.features[0].properties.council_name,
        e.features[0].properties.hotspot_id,
        e.features[0].properties.count,
        coordinates
      )
      ReactDOM.render(popup, popupDiv)

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setDOMContent(popupDiv)
        .setMaxWidth('360px')
        .addTo(map)
    })

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', 'request-pin-layer', function () {
      map.getCanvas().style.cursor = 'pointer'
    })

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'request-pin-layer', function () {
      map.getCanvas().style.cursor = ''
    })

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4))
      setLat(map.getCenter().lat.toFixed(4))
      setZoom(map.getZoom().toFixed(2))
    })

    // console.log(lng, lat, zoom)

    return () => map.remove()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeId])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        marginTop: '2em',
        minHeight: '50em',
        backgroundColor: 'black',
      }}
    >
      <div
        className='map-container'
        ref={mapContainer}
        style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
      />
      <Segment floated='right' raised>
        <MapFilter onChange={setSelectedTypeId} />
      </Segment>
    </div>
  )
}

export default Map
