import React from 'react'
import { useForm } from '@formspree/react'
import { Form, Button, Header } from 'semantic-ui-react'

const ContactForm = () => {
  const [state, handleSubmit] = useForm('mgepglwd')

  React.useEffect(() => {
    console.log(state)
  })

  return (
    <>
      <Header size='large'>Contact Us</Header>
      {state.succeeded && <div>Thank you for signing up!</div>}
      {!state.succeeded && (
        <Form onSubmit={handleSubmit}>
          <Form.Field required>
            <label htmlFor='email'>Email</label>
            <input id='email' type='email' name='email' />
          </Form.Field>
          <Form.Field>
            <label htmlFor='message'>Message</label>
            <textarea id='message' type='text' name='message' />
          </Form.Field>
          <Button primary type='submit' disabled={state.submitting}>
            Send Message
          </Button>
        </Form>
      )}
    </>
  )
}

export default ContactForm
