import { Route, Switch } from 'react-router-dom'
import { Grid, Container } from 'semantic-ui-react'
import MenuBar from './components/MenuBar'
import MobileMenuBar from './components/MobileMenuBar'
import FooterBar from './components/FooterBar'
import NotFound from './pages/_NotFound'
import Main from './pages/Main'
import Agencies from './pages/Agencies'
import Councils from './pages/Councils'
import Types from './pages/Types'
import UserProfile from './pages/UserProfile'
import Reports from './pages/Reports'
import Map from './pages/Map'
import Charts from './pages/Charts'
import ContactForm from './pages/ContactForm'
import Blog from './pages/Blog'
import Privacy from './pages/Privacy'
import Faqs from './pages/Faqs'

const App = () => {
  return (
    <Grid style={{ margin: 0, padding: '0' }} columns={1}>
      <Grid.Column only='mobile'>
        <MobileMenuBar />
      </Grid.Column>
      <Grid.Column only='tablet'>
        <MenuBar />
      </Grid.Column>
      <Grid.Column only='computer'>
        <MenuBar />
      </Grid.Column>
      <Grid.Column style={{ margin: 0, padding: '0' }}>
        <Switch>
          <Route path='/reports' component={Reports} />
          <Route path='/map' component={Map} />
          <Route>
            <Container
              text
              style={{
                marginTop: '5em',
                marginBottom: '7em',
                minHeight: '20em',
              }}
            >
              <Switch>
                <Route exact path='/' component={Main} />
                <Route path='/agencies' component={Agencies} />
                <Route path='/councils' component={Councils} />
                <Route path='/types' component={Types} />
                <Route path='/charts' component={Charts} />
                <Route path='/blog' component={Blog} />
                <Route path='/faqs' component={Faqs} />
                <Route path='/privacy' component={Privacy} />
                <Route path='/contact' component={ContactForm} />
                <Route path='/user-profile' component={UserProfile} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </Route>
        </Switch>
      </Grid.Column>
      <Grid.Column style={{ margin: 0, padding: '0' }}>
        <FooterBar />
      </Grid.Column>
    </Grid>
  )
}

export default App
