import React from 'react'
import { useStore } from '../hooks/storeContext'
import { Header, List } from 'semantic-ui-react'

const Councils = () => {
  const store = useStore()

  return (
    <>
      <Header>Councils Page</Header>
      {store.types.status === 'success' && (
        <List
          items={store.councils.results.map((ds) => {
            return {
              content: ds.councilName,
              value: ds.councilName,
              key: ds.councilId,
            }
          })}
        />
      )}
    </>
  )
}

export default Councils
