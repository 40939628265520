import React from 'react'

import { Header } from 'semantic-ui-react'

const NotFound = () => {
  return (
    <>
      <Header>Page Not Found</Header>
    </>
  )
}

export default NotFound
