import React from 'react'
import { Header, Loader, Container, List } from 'semantic-ui-react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import useContentful from '../hooks/useContentful'

const query = `
query {
  faqsCollection(order: priority_ASC) {
    items {
      sys { id }
      question
      response { json }
    }
  }
}
`

const Faqs = () => {
  let { data, errors } = useContentful(query)

  React.useEffect(() => {
    if (errors) console.log(errors)
  }, [errors])

  return (
    <>
      <Loader active={!data} size='big' content='Loading FAQs' />
      <Header size='large'>Frequently Asked Questions</Header>
      {data && (
        <List>
          {data.faqsCollection.items.map((item) => {
            return (
              <List.Item key={item.sys.id} as='a' href={`#${item.question}`}>
                {item.question}
              </List.Item>
            )
          })}
        </List>
      )}
      {data &&
        data.faqsCollection.items.map((item) => {
          return (
            <>
              <Header id={item.question}>{item.question}</Header>
              <Container>
                {documentToReactComponents(item.response.json)}
              </Container>
            </>
          )
        })}
    </>
  )
}

export default Faqs
