import React from 'react'
import { useLocation } from 'react-router-dom'
import { Segment, Dimmer, Loader } from 'semantic-ui-react'

const Reports = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  const url = process.env.REACT_APP_REPORT_SERVER_URL
  let report_path = '/dashboards/overview'
  let location = useLocation()
  report_path = location.pathname.slice(8)
  const reportRef = React.useRef(report_path)

  React.useEffect(() => {
    if (report_path !== reportRef.current) {
      setIsLoading(true)
    }

    if (isLoading) {
      const timer = setTimeout(() => setIsLoading(false), 800)
      return () => clearTimeout(timer)
    }
    reportRef.current = report_path
  }, [report_path, isLoading])

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        marginTop: '2em',
        // minHeight: '20em',
        backgroundColor: 'black',
      }}
    >
      <Segment style={{ backgroundColor: '#0F181F' }}>
        <Dimmer active={isLoading}>
          <Loader size='big' content='Loading Report' />
        </Dimmer>
        <iframe
          title='reportFrame'
          src={url + report_path}
          frameBorder='0'
          allowFullScreen
          style={{ width: '100%', height: '100%', minHeight: '55em' }}
        ></iframe>
      </Segment>
    </div>
  )
}

export default Reports
