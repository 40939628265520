import React from 'react'
import {
  Header,
  Loader,
  Grid,
  List,
  Rail,
  Sticky,
  Container,
  Divider,
  Ref,
  Image,
} from 'semantic-ui-react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import useContentful from '../hooks/useContentful'

const query = `
query {    
  blogPostCollection(limit: 10, order: publishDate_DESC) {
    items {
      sys { id }
      slug
      title
      publishDate
      body {
        json
        links {
          assets {
            block {
              sys { id }
              title
              url
              width
              height
              description
            }
          }
        }
      }
    }
  }
}
`

function getRenderOptions(links) {
  // create an asset block map
  const assetBlockMap = new Map()

  // loop through the assets and add them to the map
  for (const asset of links.assets.block) {
    assetBlockMap.set(asset.sys.id, asset)
  }

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
        const asset = assetBlockMap.get(node.data.target.sys.id)
        return <Image src={asset.url} />
      },
    },
  }
}

const Blog = () => {
  let { data, errors } = useContentful(query)
  const objectRef = React.useRef()

  React.useEffect(() => {
    if (errors) console.log(errors)
  }, [errors])

  return (
    <>
      <Loader active={!data} size='big' content='Loading blog' />
      {data && (
        <Ref innerRef={objectRef}>
          <Grid>
            <Grid.Column computer={12} width={16}>
              {data.blogPostCollection.items.map((item) => {
                return (
                  <Container key={item.sys.id} style={{ marginBottom: '3em' }}>
                    <Header id={item.slug} size='large'>
                      {item.title}
                    </Header>
                    <Header sub style={{ marginBottom: '2em' }}>
                      {new Date(item.publishDate).toLocaleDateString()}
                    </Header>
                    {documentToReactComponents(
                      item.body.json,
                      getRenderOptions(item.body.links)
                    )}
                    <Divider section />
                  </Container>
                )
              })}
            </Grid.Column>
            <Grid.Column width={1} only='computer'>
              <Rail position='right'>
                <Sticky context={objectRef} offset={100}>
                  <Header sub>All posts</Header>
                  <List relaxed='very'>
                    {data.blogPostCollection.items.map((item) => {
                      return (
                        <List.Item
                          key={item.sys.id}
                          as='a'
                          href={`#${item.slug}`}
                        >
                          {item.title}
                        </List.Item>
                      )
                    })}
                  </List>
                </Sticky>
              </Rail>
            </Grid.Column>
          </Grid>
        </Ref>
      )}
    </>
  )
}

export default Blog
