import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useStore } from '../hooks/storeContext'

import { Button, Form, Dropdown, Segment, Loader } from 'semantic-ui-react'

const USERS_URL = '/api/users'

const UserProfile = () => {
  // eslint-disable-next-line no-unused-vars
  const { user, isAuthenticated, isLoading } = useAuth0()
  const store = useStore()

  const [profile, setProfile] = React.useState()
  const [selectedCouncil, setSelectedCouncil] = React.useState()
  const [isEditing, setIsEditing] = React.useState(false)

  React.useEffect(() => {
    if (user) {
      // get the profile matching signed in user
      const fetchProfile = async () => {
        await fetch(`${USERS_URL}/${user.sub}`).then(async (response) => {
          if (response.ok) {
            const data = await response.json()
            setProfile(data)
          }
        })
      }
      fetchProfile()
    }
  }, [user])

  const handleEditClick = () => {
    setIsEditing(!isEditing)
    setSelectedCouncil(null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    // patch the profile if user changes neighborhood
    const updateProfile = async () => {
      await fetch(`${USERS_URL}/${user.sub}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify(selectedCouncil),
      }).then(async (response) => {
        if (response.ok) {
          const data = await response.json()
          setProfile(data)
        }
      })
    }

    if (selectedCouncil) {
      updateProfile()
      setIsEditing(!isEditing)
    }
  }

  return (
    <Segment style={{ minHeight: '20em' }}>
      <Loader active={isLoading} size='big' content='Loading Profile' />
      {profile && (
        <div>
          <img src={profile.picture} alt={profile.name} />
          <h2>{profile.name}</h2>
          <p>
            {profile.email} {profile.email_verified && ' (verified)'}
          </p>
          <p style={{ color: 'gray', fontSize: '0.9em' }}>
            {new Date(profile.updated_at).toLocaleDateString() +
              ' ' +
              new Date(profile.updated_at).toLocaleTimeString()}
          </p>
          <h3>{profile.user_metadata.councilName}</h3>
          {!isEditing && (
            <Button
              color='blue'
              compact
              basic
              size='tiny'
              onClick={handleEditClick}
              content='change'
            />
          )}
          {isEditing && (
            <Form>
              <Form.Field>
                <Dropdown
                  style={{ width: '24em' }}
                  selection
                  basic
                  placeholder='Select a Neighborhood'
                  name='councilSelect'
                  onChange={(e, { value }) =>
                    setSelectedCouncil({
                      councilId: value,
                      councilName: e.target.textContent,
                    })
                  }
                  options={store.councils.results.map((ds) => {
                    return {
                      text: ds.councilName,
                      value: ds.councilId,
                      key: ds.councilId,
                    }
                  })}
                />
              </Form.Field>
              <Button primary content='Submit' onClick={handleSubmit} />
              <Button secondary content='Cancel' onClick={handleEditClick} />
            </Form>
          )}
        </div>
      )}
    </Segment>
  )
}

export default UserProfile
