import React from 'react'

const MapPopup = (council_name, hotspot_id, count, coordinates) => (
  <div
    style={{
      width: 320,
      height: 180,
      margin: '5pt 0 0 0',
      padding: '5pt',
      borderRadius: '3pt',
      color: 'white',
      backgroundColor: '#333333',
    }}
  >
    <p>{council_name}</p>
    <h1>{count}</h1>
    <p>
      [{hotspot_id}] hotspot
      <br />
      {coordinates[0].toFixed(4)}, {coordinates[1].toFixed(4)}
    </p>
  </div>
)

export default MapPopup
