import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { StoreProvider } from './hooks/storeContext'
import reportWebVitals from './reportWebVitals'
import 'semantic-ui-css/semantic.min.css'
import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain='dev-mxweprth.us.auth0.com'
      clientId='wxdW6w8R80BN5Wxfp2jtXKwooZHfJNqJ'
      redirectUri={window.location.origin}
    >
      <BrowserRouter>
        <StoreProvider>
          <App />
        </StoreProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
