import React from 'react'
import { useStore } from '../hooks/storeContext'
import { Header, List } from 'semantic-ui-react'

const Agencies = () => {
  const store = useStore()

  if (store.agencies.results) {
    store.agencies.results.sort((a, b) => {
      return a.agencyName < b.agencyName ? -1 : 1
    })
  }

  return (
    <>
      <Header>Agencies Page</Header>
      {store.agencies.status === 'success' && (
        <List
          items={store.agencies.results.map((ds) => {
            return {
              content: ds.agencyName,
              value: ds.agencyName,
              key: ds.agencyId,
            }
          })}
        />
      )}
    </>
  )
}

export default Agencies
