import React from 'react'
import { useStore } from '../hooks/storeContext'
import { Dropdown } from 'semantic-ui-react'

const MapFilter = ({ onChange }) => {
  const [types, setTypes] = React.useState()
  const store = useStore()

  React.useEffect(() => {
    if (store.types.isLoaded) {
      const sortedTypes = store.types.results
      sortedTypes.sort((a, b) => {
        return a.typeName < b.typeName ? -1 : 1
      })
      setTypes(sortedTypes)
    }
  }, [store.types])

  return (
    <>
      {types && (
        <>
          <h4>Type</h4>
          <Dropdown
            // style={{ width: '24em' }}
            selection
            basic
            placeholder='Select'
            name='typeSelect'
            onChange={(e, { value }) => onChange(value)}
            options={types.map((ds) => {
              return {
                text: ds.typeName,
                value: ds.typeId,
                key: ds.typeId,
              }
            })}
          />
        </>
      )}
    </>
  )
}

export default MapFilter
