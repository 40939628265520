import React from 'react'
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts'
import { useStore } from '../hooks/storeContext'

import { Header } from 'semantic-ui-react'

const Charts = () => {
  const store = useStore()
  const [results, setResults] = React.useState()
  const [data, setData] = React.useState()
  const url = 'https://dev-api.311-data.org/requests/counts/open/types'
  let types = store.types.results

  React.useEffect(() => {
    // getting data from API
    if (!results) {
      const fetchResults = async () => {
        await fetch(url).then(async (response) => {
          if (response.ok) {
            const responseJson = await response.json()
            setResults(responseJson)
          }
        })
      }
      fetchResults()
    }

    // merge the types in to assign color
    if (types && results) {
      const mergeById = (a1, a2) =>
        a1.map((itm) => ({
          ...a2.find((item) => item.typeId === itm.typeId),
          ...itm,
        }))
      const merged = mergeById(types, results)
      setData(merged)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, types])

  const [isDefaultLegend, setDefaultLegend] = React.useState(true)
  const smallChartWidth = 900

  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < smallChartWidth && isDefaultLegend) {
        setDefaultLegend(false)
      } else if (
        window.innerWidth >= smallChartWidth &&
        isDefaultLegend === false
      ) {
        setDefaultLegend(true)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // make sure to call resize once on load

    return () => window.removeEventListener('resize', handleResize)
  }, [isDefaultLegend])

  return (
    <>
      <Header size='large'>Charts Page</Header>
      <ResponsiveContainer width='100%' height={500}>
        <PieChart>
          <Pie
            data={data}
            nameKey='typeName'
            dataKey='typeCount'
            cx='50%'
            cy='50%'
            innerRadius={50}
            outerRadius={120}
            label
          >
            {data &&
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
          </Pie>
          {isDefaultLegend ? (
            <Legend layout='vertical' verticalAlign='middle' align='right' />
          ) : (
            <Legend layout='horizontal' verticalAlign='bottom' />
          )}
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}

export default Charts
