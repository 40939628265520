import React from 'react'
import { useStore } from '../hooks/storeContext'
import { Header, List } from 'semantic-ui-react'

const Types = () => {
  const store = useStore()

  return (
    <>
      <Header>Types Page</Header>
      {store.types.status === 'success' && (
        <List
          items={store.types.results.map((ds) => {
            return {
              content: <span style={{ color: ds.color }}>{ds.typeName}</span>,
              value: ds.typeName,
              key: ds.typeId,
            }
          })}
        />
      )}
    </>
  )
}

export default Types
