import React from 'react'
import useApiResource from './useApiResource'

export const StoreContext = React.createContext()

export const useStore = () => React.useContext(StoreContext)

export const StoreProvider = ({ children }) => {
  const contextValue = []
  // contextValue['openPins'] = useApiResource('requests/pins/open')
  contextValue['openTypesCounts'] = useApiResource('requests/counts/open/types')
  contextValue['types'] = useApiResource('types')
  contextValue['councils'] = useApiResource('councils')
  contextValue['agencies'] = useApiResource('agencies')

  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  )
}
