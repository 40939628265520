import React from 'react'

const useJsonResource = (path, options) => {
  const cache = React.useRef({})
  const [results, setResults] = React.useState([])
  const [error, setError] = React.useState()
  const [status, setStatus] = React.useState('idle')

  const getData = () => {
    setStatus('loading')
    fetch(path, options)
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json()
          cache.current[path] = data // add to cache
          setResults(data)
          setError(null)
          setStatus('success')
        } else {
          setStatus('error')
          setError(await response.text())
        }
      })
      .catch((err) => {
        setStatus('error')
        setError(err.message)
      })
  }

  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = {
    status,
    results,
    error,
  }

  return contextValue
}

export default useJsonResource
