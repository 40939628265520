import React from 'react'
import { Link } from 'react-router-dom'
import useJsonResource from '../hooks/useJsonResource'
import AuthButton from './AuthButton'
import { Icon, Menu, Sidebar } from 'semantic-ui-react'

const MobileMenuBar = () => {
  const [visible, setVisible] = React.useState(false)
  const url = '/data.json'
  const data = useJsonResource(url)
  const toggleMenu = () => {
    setVisible(!visible)
  }

  return (
    <>
      <Menu size='large' fixed='top' inverted>
        <Menu.Item>
          <Icon bordered link name='bars' onClick={toggleMenu}></Icon>
        </Menu.Item>
        <Menu.Item as={Link} to='/' name='home'>
          <Icon circular inverted color='teal' name='users' />
        </Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item>
            <AuthButton />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Sidebar
        as={Menu}
        animation='overlay'
        inverted
        onHide={() => setVisible(false)}
        vertical
        visible={visible}
        width='thin'
        color='teal'
      >
        <Menu.Item as={Link} to='/' name='home' onClick={toggleMenu} />
        <Menu.Item
          as={Link}
          to='/councils'
          name='councils'
          onClick={toggleMenu}
        />
        <Menu.Item as={Link} to='/types' name='types' onClick={toggleMenu} />
        <Menu.Item
          as={Link}
          to='/agencies'
          name='agencies'
          onClick={toggleMenu}
        />
        <Menu.Item as={Link} to='/faqs' name='faqs' onClick={toggleMenu} />
        <Menu.Item as={Link} to='/blog' name='blog' onClick={toggleMenu} />
        <Menu.Item
          as={Link}
          to='/contact'
          name='contact'
          onClick={toggleMenu}
        />
        <Menu.Item as={Link} to='/charts' name='charts' onClick={toggleMenu} />
        <Menu.Item as={Link} to='/map' name='map' onClick={toggleMenu} />
        <Menu.Item as={Link} to='/user-profile' name='profile' />
        <Menu.Item>
          Reports
          <Menu.Menu>
            {data.results.map((i) => {
              return (
                <Menu.Item
                  as={Link}
                  to={'/reports' + i.url}
                  name={i.title}
                  key={i.url}
                  onClick={toggleMenu}
                />
              )
            })}
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    </>
  )
}

export default MobileMenuBar
