import React from 'react'
import { useStore } from '../hooks/storeContext'
import { Header, StatisticGroup } from 'semantic-ui-react'

const Agencies = () => {
  const store = useStore()

  return (
    <>
      <Header>Home Page</Header>
      {store.openTypesCounts.status === 'success' && (
        <StatisticGroup
          color='blue'
          horizontal
          items={store.openTypesCounts.results.map((ds) => {
            return {
              label: ds.typeName,
              value: ds.typeCount.toLocaleString(),
              key: ds.typeId,
            }
          })}
        />
      )}
    </>
  )
}

export default Agencies
