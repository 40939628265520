import React from 'react'

const useApiResource = (path) => {
  let BASE_URL = 'https://dev-api.311-data.org'

  if (process.env.REACT_APP_API_HOST) {
    BASE_URL = process.env.REACT_APP_API_HOST
  }

  const resource = `${BASE_URL}/${path}`

  const cache = React.useRef({})
  const [results, setResults] = React.useState()
  const [error, setError] = React.useState()
  const [status, setStatus] = React.useState('idle')
  const [isLoaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    if (cache.current[resource]) {
      const data = cache.current[resource]
      setResults(data)
      setError(null)
    } else {
      setStatus('loading')
      fetch(resource)
        .then(async (response) => {
          if (response.ok) {
            const data = await response.json()
            cache.current[resource] = data // add to cache
            setResults(data)
            setError(null)
            setStatus('success')
            setLoaded(true)
          } else {
            setError(await response.text())
            setStatus('error')
          }
        })
        .catch((err) => {
          setError(err.message)
          setStatus('error')
        })
    }
  }, [resource])

  const contextValue = {
    status,
    results,
    error,
    isLoaded,
  }

  return contextValue
}

export default useApiResource
