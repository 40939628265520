import React from 'react'
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from 'semantic-ui-react'

const FooterBar = () => (
  <Segment
    inverted
    vertical
    style={{ margin: 0, padding: '3em 0em' }}
  >
    <Container textAlign='center'>
      <Grid divided inverted stackable>
        <Grid.Column width={3}>
          <Header inverted as='h4' content='Group 1' />
          <List link inverted>
            <List.Item as='a'>Link One</List.Item>
            <List.Item as='a'>Link Two</List.Item>
            <List.Item as='a'>Link Three</List.Item>
            <List.Item as='a'>Link Four</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <Header inverted as='h4' content='Group 2' />
          <List link inverted>
            <List.Item as='a'>Link One</List.Item>
            <List.Item as='a'>Link Two</List.Item>
            <List.Item as='a'>Link Three</List.Item>
            <List.Item as='a'>Link Four</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <Header inverted as='h4' content='Group 3' />
          <List link inverted>
            <List.Item as='a'>Link One</List.Item>
            <List.Item as='a'>Link Two</List.Item>
            <List.Item as='a'>Link Three</List.Item>
            <List.Item as='a'>Link Four</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={7}>
          <Header inverted as='h4' content='Footer Header' />
          <p>
            Extra space for a call to action inside the footer that could help
            re-engage users.
          </p>
        </Grid.Column>
      </Grid>

      <Divider inverted section />
      <Image centered size='mini' src='/logo512.png' />
      <List horizontal inverted divided link size='small'>
        <List.Item as='a' href='/contact'>
          Contact Us
        </List.Item>
        <List.Item as='a' href='/privacy'>
          Terms and Conditions
        </List.Item>
        <List.Item as='a' href='/privacy'>
          Privacy Policy
        </List.Item>
      </List>
    </Container>
  </Segment>
)

export default FooterBar
