import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'semantic-ui-react'

const AuthButton = () => {
  const { isAuthenticated, loginWithPopup, logout } = useAuth0()

  function handleClick(e) {
    e.preventDefault()
    loginWithPopup()
  }

  return isAuthenticated ? (
    <Button
      active
      color='teal'
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Logout
    </Button>
  ) : (
    <Button active onClick={handleClick}>
      Login
    </Button>
  )
}

export default AuthButton
