import React from 'react'
import {
  Header,
  Loader,
  // Container,
} from 'semantic-ui-react'
import useContentful from '../hooks/useContentful'
import ReactMarkdown from 'react-markdown'

const query = `
query {
  simplePageCollection(where: {slug: "privacy"}) {
    items {
      title
      markdown
      body { json }
    }
  }
}
`

const Privacy = () => {
  let { data, errors } = useContentful(query)

  React.useEffect(() => {
    if (errors) console.log(errors)
  }, [errors])

  return (
    <>
      <Loader active={!data} size='big' content='Loading blog' />
      {data && (
        <>
          <Header size='large'>
            {data.simplePageCollection.items[0].title}
          </Header>
          <ReactMarkdown
            children={data.simplePageCollection.items[0].markdown}
          />
        </>
      )}
    </>
  )
}

export default Privacy
